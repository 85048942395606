@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.footerWrapper {
  background-color: #eff0f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-left: 100px;
  margin-right: 90px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @include phone {
    height: auto;
    padding: 24px 24px 48px;
    margin-left: auto;
    margin-right: auto;
  }
}

.footerButtons {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;

  @include phone {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 220px;
    align-items: center;
  }
}
.social {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 200px;

  @include phone {
    justify-content: center;
    margin-top: 24px;
  }
}

.actions {
  color: $primary;
  background-color: $secondary;
  border: none;
  display: flex;
  align-items: center;
  font-weight: 700;
  border-radius: 4px;

  &:hover {
    color: white !important;
    background-color: $primary !important;
  }
}

.menu {
  display: flex;
  align-items: center;
  gap: 4px;

  @include phone {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.menuItem {
  color: #292929;
  font-weight: 700;
  font-size: 16px;
}

.post {
  background-color: $primary;
  color: white;
  font-weight: 700;
  border-radius: 4px;
  border: none;

  &:hover {
    color: white !important;
    background-color: $primary-hover !important;
  }
}

.footerCompanyText {
  position: absolute;
  bottom: 20px;
}
