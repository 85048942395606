@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border-left: 1px solid #ced2e5;
  padding-left: 48px;
  min-height: 50vh;
  width: 80%;

  @include normal-screen {
    width: 100%;
  }

  @include small-screen {
    border-left: none;
    padding-left: 0px;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.iconWrapper {
  min-width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: $secondary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemWrapper {
  margin-top: 48px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  width: 80%;
  gap: 12px;
  border-bottom: 1px solid #ced2e5;
  padding-bottom: 24px;

  @include phone {
    grid-template-columns: 1fr;
  }
  @include normal-screen {
    width: 100%;
  }
}

.loading {
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricingCardWrapper {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  width: 80%;
  justify-content: space-evenly;

  @include normal-screen {
    width: 100%;
  }

  @include phone {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}

.pricingCard {
  border-radius: 12px;
  border: 2px solid #ced2e5;
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.16);
  padding: 24px 12px 12px 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 240px;
  width: 160px;
  justify-content: space-between;

  @include phone {
    margin-left: auto;
    margin-right: auto;
  }
}

.invitationWrapper {
  padding: 43px 40px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-color: #eff0f5;
  border-radius: 12px;
}
