@import 'src/styles/variables.scss';

.fullWidth {
  width: 100%;
}

.container {
  &.disabled {
    opacity: 0.6;
  }
}

.wrapper {
  display: block;
  position: relative;
  color: $grey-medium-txt;
}

.label {
  color: $secondary-txt;
  margin-bottom: 6px;
  font-size: $font-size-12;
  font-weight: $font-weight-400;
}

.input {
  border-radius: $border-radius-button;
  padding: 0 14px;
  color: $primary-txt;
  font-size: $font-size-14;
  background: #fff;
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  box-shadow: none !important;
  border: 1px solid #ced2e5 !important;

  &.middle {
    height: 40px;
  }

  &.small {
    height: 32px;
  }

  &.large {
    height: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    font-family: $font-family--default;
    color: $grey-dark-2;
  }
}

.inputIcon {
  padding: 0 32px 0 37px !important;
}

.icon {
  position: absolute;
  //right: 8px;
  left: 8px;
  top: 50%;
  z-index: 0;
  transform: translateY(-50%);
}

.loader {
  position: absolute;
  right: 8px;
  top: 50%;
  z-index: 0;
  transform: translateY(-50%);
  color: $primary;
}

:global {
  .ant-input {
    border-radius: $border-radius-button;
    padding: 0 14px;
    color: $primary-txt;
    font-size: $font-size-14;
    background: $tertiary-bg;
    box-shadow: none !important;
    &::placeholder {
      color: $grey-medium-txt;
      font-size: $font-size-14;
    }

    &.middle {
      height: 40px;
    }

    &.small {
      height: 32px;
    }
    &:disabled,
    &:disabled:hover {
      border: 1px solid transparent;
    }

    &:hover {
      background: $default-bg-hover;
      &:not(:focus) {
        border-color: transparent;
      }
    }
  }
  .ant-input-prefix {
    margin-right: 0;
    color: inherit !important;
  }
  .ant-input-affix-wrapper:hover {
    border: none;
    border-right-width: 0px;
  }
}
