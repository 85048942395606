@use 'src/styles/variables' as *;

.wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/forgot-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  align-items: center;
  padding-left: 120px;
  justify-content: center;
}

.form {
  background-color: $white;
  padding: 32px;
  border-radius: 12px;
  min-width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}

.text {
  font-family: $font-family--questrial;
  font-size: 20px;
  line-height: 24px;
  color: $grey-dark-2;
}

.link {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $primary;

  padding: 0 !important;
  display: block;

  &:hover {
    background-color: $white;
    color: $primary;
  }
}

.forgotBlock {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 8px;
}

.privacy {
  color: $grey-dark-2;
  max-width: 270px;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
}

.termsLink {
  color: inherit;
  font-weight: 700;
  margin: 0 4px;
  cursor: pointer;

  &:hover {
    color: $grey-dark-3;
  }

  &:last-child {
    margin-right: 0;
  }
}
