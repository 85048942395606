@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.password {
  border: 1px solid #ced2e5;
  border-radius: 12px;
  padding: 24px;
  height: fit-content;
}

.form {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  gap: 20px;
}

.row {
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
}

.hint {
  border-radius: 4px;
  background: #e7e9f1;
  backdrop-filter: blur(10px);
  display: flex;
  padding: 3px 8px;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #7f86a4;

  &.invalid {
    background: #ff4b53;
    color: white;
  }

  &.valid {
    background: #02c29a;
    color: white;
  }
}
