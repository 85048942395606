@import 'variables';

@mixin phone {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

@mixin small-screen {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin normal-screen {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

@mixin reset-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    background: none;
    border: none;
    outline: none;
  }

  //&:focus {
  //  background: none;
  //  border: none;
  //  outline: none;
  //}
}

@mixin page-title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  font-family: Inter, sans-serif;
  color: $black;
}

@mixin mobile-page-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  font-family: Inter, sans-serif;
  color: $black;
}

@mixin create-title {
  font-family: Inter, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.72px;
  color: #000;
}

@mixin label {
  margin-bottom: 8px;
  display: block;
  color: $grey-dark-1;
  font-family: $font-family--default;

  &.required {
    &:after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

@mixin hint {
  background: #fffbd5;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: $font-family--default;
  font-weight: 400;
}
