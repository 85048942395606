@import 'src/styles/variables.scss';

.group {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  font-size: 10px;
  color: $grey-txt;
  opacity: 0.64;
  font-weight: $font-weight-500;
  margin-bottom: 12px;
}
