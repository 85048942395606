@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.input {
  border-radius: $border-radius-button !important;
  padding: 0 12px;
  color: $primary-txt;
  font-size: $font-size-14;
  background: $tertiary-bg !important;
  border: 1px solid transparent !important;
  transition: all 0.3s ease-out;
  box-shadow: none !important;
  height: 40px;
  display: flex !important;
  align-items: center !important;

  &.small {
    height: 32px;
  }

  &.large {
    height: 58px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    font-family: $font-family--default;
    color: $grey-dark-2;
  }

  &.maskWrapper {
    padding-left: 14px !important;
  }
}

.wrapper {
  position: relative;
}

.mask {
  position: absolute;
  top: 9px;
  left: 10px;
  z-index: 1;
}

:global {
  .ant-form-item-has-error {
    .ant-input-number-status-error {
      border-color: #e2453a !important;
      outline: 2px solid rgba(226, 69, 58, 0.16) !important;

      &:focus {
        border-color: #e2453a !important;
        outline: 2px solid rgba(226, 69, 58, 0.16) !important;
      }
    }
  }

  .react-tel-input .form-control {
    height: 40px;
  }
}
