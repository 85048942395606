@import 'src/styles/variables.scss';

.wrap {
  position: relative;
}

:global {
  .ant-form-item-explain {
    margin-left: 4px;
    line-height: 16px;
    font-size: 12px;
    &-error {
      margin-top: auto;
    }
    &-connected {
      color: $secondary-txt;
      margin-top: 5px;
    }
  }
}

.fullWidth {
  width: 100%;
}
