@import 'src/styles/variables.scss';

.wrapper {
  :global {
    .ant-steps-item-icon {
      font-size: $font-size-16;
      font-family: $font-family--default;
    }

    .ant-steps-item-wait .ant-steps-item-icon {
      background-color: $tertiary-bg;
      border-color: $tertiary-bg;
      text-align: center;
      vertical-align: middle;
    }

    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: $primary;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
      background-color: $primary;
      border-color: $primary;

      svg {
        color: $white;
      }
    }
  }
}

.large {
  :global {
    .ant-steps-item-icon {
      width: 32px !important;
      height: 32px !important;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.medium {
  :global {
    .ant-steps-item-icon {
      width: 20px !important;
      height: 20px !important;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: $font-size-14;
    }

    .ant-steps-item-tail {
      left: 10px !important;
      padding: 24px 0 6px !important;
    }

    .ant-steps-icon {
      top: 0;
    }
  }
}

.small {
  :global {
    .ant-steps-item-icon {
      width: 16px !important;
      height: 16px !important;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: $font-size-11;
      font-weight: $font-weight-700;
    }

    .ant-steps-item-tail {
      left: 8px !important;
      padding: 24px 0 6px !important;
    }

    .ant-steps-icon {
      top: 0;
    }
  }
}
