@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  margin-top: 120px;
  margin-left: 100px;
  margin-right: 90px;
  padding-top: 24px;
  padding-bottom: 48px;
  display: flex;

  @include tablet {
    margin: 90px 24px 48px;
  }
}

.progressLabel {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.form {
  border-left: 1px solid #ced2e5;
  padding-left: 24px;
  margin-left: 32px;
  width: 100%;
  overflow: auto;

  @include phone {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }
}

.agencyItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 8px;
}

.agenciesWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.errorMessage {
  color: rgb(255, 77, 79);
  font-size: 13px;
  font-weight: 400;
}
.inputError {
  border: 1px solid rgb(255, 77, 79) !important;
}

.menuItem button {
  color: $primary;
  padding-left: 0;
  font-size: 18px;
  font-style: normal;
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: 700;
  line-height: 26px; /* 130% */
  letter-spacing: -0.4px;

  &:focus {
    color: black;
  }
}
.active button {
  color: black;
}

.anchor {
  position: absolute;
  top: -120px;
}

.leftMenu {
  max-width: 300px;
  position: sticky; /* Make it sticky */
  top: 140px; /* Stick to the top of the page */
  height: 50vh;

  @include phone {
    display: none;
  }
}

.formBlock {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #ced2e5;
  margin-top: 32px;
  width: 100%;
  position: relative;
}

.subTitle {
  width: 32px;
  height: 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
  color: $primary;
  background-color: $secondary;
  border-radius: 50%;
  text-align: center;
  padding-top: 5px;
}

.propertyItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 12px;
  justify-content: center;
  margin-top: 24px;

  @include phone {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}

.propertyItemStart {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  margin-bottom: 12px;
  justify-content: center;
  margin-top: 24px;

  @include phone {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}

.filterSwitch {
  border: 1px solid #ced2e5;
  border-radius: 6px;
  padding: 3px;
  background-color: #eff0f5;
  width: fit-content;

  @include phone {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
}

.parameterTypes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-top: 16px;
}
.comments {
  border: 1px solid #ced2e5 !important;
  background-color: white;
  margin-top: 12px;
}

.propertyCommentItem {
  margin-top: 24px;
}
:global {
  .ant-progress .ant-progress-bg {
    background-color: $primary;
  }
}

.upload {
  border-radius: 16px;
  border: 1px dashed #8e8888;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  margin-top: 8px;
}

.uploadBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

:global {
  .ant-upload-wrapper {
    width: 100%;
    height: 100%;
  }
}

.uploadedFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  position: relative;
  border: none;
}

.chooseButton {
  padding: 10px 36px !important;
  background-color: #e7e9f1 !important;
  color: #7f86a4 !important;
}

.uploadWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  flex-direction: column;
}

.phone {
  display: flex;
}

.phoneContainer {
  width: 100%;
  border: 1px solid #ced2e5;
  border-radius: 6px;
}

.phoneButton {
  background-color: transparent !important;
  border: none !important;

  div {
    &:hover,
    :focus {
      background-color: transparent !important;
    }
  }
}

.inviteWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
  margin-top: 16px;

  @include phone {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
}

.inviteInput {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 50%;

  @include phone {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}

.Submit {
  width: 100%;
  text-align: center;
  margin-top: 32px;
}

.uploadedImage {
  border-radius: 6px;
}

.deleteButton {
  right: 10px;
  top: 10px;
  position: absolute;
  color: white;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px 8px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.deleteDocButton {
  right: -8px;
  top: -8px;
  position: absolute;
  color: white;
  font-weight: 700;
  width: 24px;
  height: 24px;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 2px 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.uploadedDocFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  position: relative;
  border: none;
  background-color: #e7e9f1;
  padding: 8px 16px;
  border-radius: 8px;
}
