@import 'styles/variables';
@import 'styles/mixins';

:global {
  .grecaptcha-badge {
    display: none;
    bottom: 120px !important;

    @include tablet {
      display: none;
    }
  }
}

.navBar {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10; /* Make sure it's above the content */
  width: 100%; /* Adjust width if necessary */
  display: flex;
  justify-content: center; /* Ensure it's centered horizontally */

  @include tablet {
    display: none;
  }
}

.content {
  height: 100vh;
}

.mobileHeader {
  display: none;
  background-color: #eff0f5;
  height: 48px;
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  border-radius: 6px;
  z-index: 50;

  @include tablet {
    display: flex;

    &.opened {
      background-color: none;
    }
  }
}

.uploadedImage {
  border-radius: 50%;
}


.mobileMenu {
  height: 100vh;
  width: 200px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 12000;
  transform: translateX(-100%); // Hidden off-screen by default
  transition: 0.4s;

  &.opened {
    transform: translateX(0); // Slide into view
  }
}

.logo {
  color: white;
  display: flex;
  justify-content: flex-start;
  margin-left: 24px;
  width: 100%;
  height: 100%;
  align-items: center;
}

.profile {
  margin-top: 12px;
  margin-right: 24px;
}

.menu {
  width: 20px;
  height: 20px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  top: 14px;
  left: 12px;
  bottom: 12px;
  outline: none;
  z-index: 9000;

  &.rotate {
    transform: rotateZ(180deg);
  }

  .menuLine:first-child {
    width: 80%;
    height: 2px;
    background: #000;
    transition: 0.6s;

    &.rotate {
      transform: rotateZ(45deg);
    }
  }

  .menuLine:nth-child(2) {
    width: 80%;
    height: 2px;
    margin-top: 0.7rem;
    background: #000;
    transition: 0.6s;

    &.rotate {
      transform: translate(0, -0.9rem) rotateZ(-45deg);
    }
  }

  @include tablet {
    display: flex;
  }
}

.shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 10000;
}

:global {
  .mapboxgl-ctrl-attrib {
    display: none;
  }

  .mapboxgl-ctrl .mapboxgl-ctrl-logo {
    display: none;
  }
}

.filterWrapper {
  display: none;
  @include tablet {
    position: absolute;
    padding: 70px 16px 10px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    background-color: white;
    overflow-x: auto;
    border-radius: 0;
  }
}

.menuContent {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  margin-left: 16px;
  margin-top: 24px;
}

.menuItem {
  color: #292929;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 4px 10px;
  line-height: 120%;

  &.active {
    color: $primary;
  }
}

.leftMenu {
  width: 200px;
  margin-left: 24px;
  height: 50vh;
}

.profileMenuItem button {
  color: $primary;
  padding-left: 0;
  font-size: 18px !important;
  font-style: normal;
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: 700;
  line-height: 26px; /* 130% */
  letter-spacing: -0.4px;

  &:focus {
    color: black;
  }
}
.active button {
  color: black;
}

.logoutItem button {
  color: $grey;
  padding-left: 0;
  font-size: 18px;
  font-style: normal;
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: 700;
  line-height: 26px; /* 130% */
  letter-spacing: -0.4px;

  &:focus {
    color: black;
  }
}
