@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 4px;
  margin-bottom: 24px;
}

:global {
  .ant-tag-checkable {
    background: $tertiary-bg !important;
    padding: 4px 20px !important;
  }

  .ant-tag-checkable-checked {
    background-color: $primary-bg !important;
    font-weight: 700 !important;
  }
}

.add {
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgba(0, 188, 75, 0.6);
  //color: white;
  border-radius: 4px;
  padding: 4px 20px !important;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.label {
  @include label;
}
