@import 'src/styles/variables.scss';

.tag {
  max-width: 80px;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  &.nowrap {
    white-space: nowrap;
    min-width: fit-content;
  }
}

.pending {
  background-color: $waiting;
  color: $waiting-txt;
}

.inProgress {
  background-color: $in-progress;
  color: $in-progress-txt;
}

.warning {
  background-color: $warning;
  color: $error;
}

.complete {
  background-color: $complete;
  color: $complete-txt;
}

.canceled {
  background-color: $color-simple-light-gray;
  color: $secondary-txt;
}
