@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border-radius: 12px;
  background: #fff;
  height: 100%;

  @include phone {
    padding: 0;
  }
}

.wrapper::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari, Edge) */
}
