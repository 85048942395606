@import 'src/styles/variables.scss';

.wrapper {
  display: flex;
  align-items: center;

  :global {
    .ant-checkbox {
      top: 0;
    }

    .ant-checkbox-checked::after {
      border-radius: $border-radius-checkbox;
      border-color: $primary-bg-hover;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      margin-bottom: 2px;
    }

    .ant-checkbox .ant-checkbox-inner {
      background-color: white;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $primary-bg;
      border-color: $primary-bg;
    }

    .ant-checkbox-inner {
      padding: 4px;
      border-radius: $border-radius-checkbox;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner {
      border-color: $primary-bg-hover;
    }
  }
}
