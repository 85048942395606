@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

:global {
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    color: $grey-medium-txt;
    background-color: $tertiary-bg;
  }

  .ant-table-thead > tr > th {
    color: $grey-medium-txt;
    background: $tertiary-bg;
  }

  .ant-table-column-title {
    flex: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  th.ant-table-cell {
    flex: none;
    font-family: $font-family--default;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $black !important;

    @include tablet {
      white-space: nowrap;
    }
  }

  .ant-table-column-sorters {
    color: $grey-txt;
    justify-content: flex-start;
  }
}
