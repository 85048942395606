@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.form {
  background-color: $white;
  padding: 32px;
  border-radius: 16px;
  min-width: 420px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  position: relative;
  border: 1px solid #ced2e5;

  @include small-screen {
    width: 100%;
    padding: 20px;
    border: none;
  }

  @include tablet {
    width: 100%;
    gap: 14px;
    border: none;
  }

  @include phone {
    width: 100%;
    padding: 20px;
    border: none;
  }
}

.text {
  font-size: 36px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
  color: $black;
  width: 100%;
}

.link {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $primary;

  &.forgotLink {
    padding: 0 !important;
    display: block;

    &:hover {
      background-color: $white;
      color: $primary;
    }
  }
}

.checkboxes {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
}

.forgotBlock {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.privacy {
  color: $grey-dark-2;
  max-width: 270px;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
}

.termsLink {
  font-weight: 700;
  margin: 0 4px;
  cursor: pointer;
  color: $blue;

  &:hover {
    color: #0261adff;
  }

  &:last-child {
    margin-right: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @include small-screen {
    padding: 0;
  }
}

.logo {
  display: none;

  @include tablet {
    display: block;
  }
}

.backLink {
  color: #8e53e8;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
}
