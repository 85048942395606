@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
@import 'variables';
@import 'mixins';

html {
  font-size: 62.5%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  font-family: $font-family--default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  font-family: $font-family--questrial;
}

ul {
  padding: 0;
}

.ant-picker-panels {
  @include tablet {
    flex-direction: column !important;
  }
}

.ant-picker-active-bar {
  @include tablet {
    width: 0;
  }
}
