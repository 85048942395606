@import 'src/styles/variables.scss';

.header {
  grid-area: header;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-4;
  position: relative;
  z-index: 100;

  @media (max-width: 991px) {
    padding: 14px 16px;
    position: sticky;
    max-width: 100vw;
    box-sizing: border-box;
  }
}
