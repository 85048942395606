@import 'src/styles/variables.scss';

.dropdownButton {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: #eff0f5;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $grey-dark-2;
  }
}

.dropdownItem {
  min-width: 150px;
}
