@import 'src/styles/variables.scss';

:global {
  .ant-switch {
    height: 20px !important;
    line-height: 20 !important;
    min-width: 36px !important;
  }

  .ant-switch-handle {
    height: 16px !important;
    width: 16px !important;
  }

  .ant-switch-checked {
    background-color: $primary-bg !important;
  }

  .ant-switch.ant-switch-small.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 19px);
  }
}
