@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  padding: 144px 100px 72px;
  border-radius: 12px;
  background: #fff;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-start;
  gap: 32px;
  @include small-screen {
    padding: 94px 0 0 0;
    flex-direction: column;
    align-items: center;
  }

  @include phone {
    width: 100%;
    padding: 94px 16px 0;
  }
}

.propertyContents {
  @include small-screen {
    width: 100%;
    text-align: start;
    padding: 0 16px;
  }
}

.components {
  display: flex;
  gap: 24px; /* Spacing between items */
  padding: 16px;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  text-align: left;

  &.more-than-4 {
    justify-content: space-around;
  }
}

.agencyWrapper {
  border: 1px solid #ced2e5;
  border-radius: 12px;
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.16);
  padding: 24px 16px 40px;
  max-width: 320px;
}

.starWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
}

.topPart {
  text-align: center;
  border-bottom: 1px solid #ced2e5;
  padding-bottom: 16px;
}

.middlePart {
  margin-top: 24px;
  border-bottom: 1px solid #ced2e5;
  padding-bottom: 16px;
}

.agencyItem {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.bottomPart {
  text-align: left;
  padding-top: 16px;
}
