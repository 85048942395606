@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border-radius: 12px;
  padding: 12px 0;
  margin-bottom: 16px;

  @include phone {
    width: 100%;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.iconWrapper {
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: $secondary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: #8e53e8;
  text-decoration: none;
}

.modalComment {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.shareButtonGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 24px;
}

.shareButton {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
  letter-spacing: -0.4px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
}

.netWorkShareButton {
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  gap: 8px;
  border: 1.5px solid #ced2e5 !important;
  border-radius: 8px !important;
  height: 50px;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 26px !important; /* 130% */
  letter-spacing: -0.4px !important;
  padding: 16px !important;

  &:hover {
    color: #8e53e8 !important; // Example: change text color on hover
    border-color: #8e53e8 !important;
    cursor: pointer; // Change cursor to indicate clickability
    transition: all 0.3s ease; // Smooth transition for all properties
  }
}

.shareIconWrapper {
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: $secondary;
  display: flex;
  align-items: center;
  justify-content: center;
}
