@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border: 1px solid #ced2e5;
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.16);
  margin-top: 16px;
  z-index: 1150;

  @include phone {
    width: 100%;
  }
}

.imageWrapper {
  display: flex;
  align-items: center;
}

.agenciesImage {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 6px;
}

.customRadio {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #d9d9d9; /* Customize the border */
  border-radius: 12px;
  padding: 8px;
  transition: all 0.3s;
  cursor: pointer;
  width: 100%;
}

.customRadio:hover {
  border-color: $primary; /* Highlight border on hover */
}

.radioContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.form {
  padding-top: 16px;
}
