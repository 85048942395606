@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.imageGrid {
  display: grid;
  grid-template-columns: 5fr 8fr;
  gap: 12px; /* Spacing between images */
}
.noLeftImages {
  grid-template-columns: 1fr !important; /* 1 column layout when no images are left */
  justify-items: center; /* Optionally center the content */
}

:global {
  .image-gallery-slide .image-gallery-image {
    height: 70vh !important;
    width: 100% !important;
    object-fit: contain !important;
  }
  .image-gallery-thumbnail-image {
    height: 80px !important;
  }
}

.oneLeftImages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.leftColumn {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-around;
}

.leftImage {
  width: 100%;
  height: 210px;
  object-fit: cover;
  border-radius: 8px;

  @include phone {
    height: 150px;
  }
}

.leftImageContainter {
  overflow: hidden; /* Hides the part of the image that goes beyond the container */
  width: 100%;
  height: 210px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;

  @include phone {
    height: 100px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container area */
    transition: transform 0.5s ease; /* Smooth transition for zoom with a duration of 0.5s */

    &:hover {
      transform: scale(1.1); /* Scale image to 120% on hover */
    }
  }
}

.oneLeftImageContainer {
  overflow: hidden; /* Hides the part of the image that goes beyond the container */
  width: 100%;
  height: 430px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;

  @include phone {
    height: 100px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container area */
    transition: transform 0.5s ease; /* Smooth transition for zoom with a duration of 0.5s */

    &:hover {
      transform: scale(1.1); /* Scale image to 120% on hover */
    }
  }
}

.rightImageContainter {
  overflow: hidden; /* Hides the part of the image that goes beyond the container */
  width: 100%;
  height: 430px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;

  @include phone {
    height: 210px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container area */
    transition: transform 0.5s ease; /* Smooth transition for zoom with a duration of 0.5s */

    &:hover {
      transform: scale(1.1); /* Scale image to 120% on hover */
    }
  }
}

.btmImageContainer {
  overflow: hidden; /* Hides the part of the image that goes beyond the container */
  width: calc(25% - 10px);
  height: 150px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;

  @include phone {
    height: 80px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container area */
    transition: transform 0.5s ease; /* Smooth transition for zoom with a duration of 0.5s */

    &:hover {
      transform: scale(1.1); /* Scale image to 120% on hover */
    }
  }
}

.bottomRow {
  grid-column: span 3; /* Make this span all columns */
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.bottomImageContainer {
  overflow: hidden;
  width: calc(25% - 10px);
  height: 150px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  @include phone {
    height: 80px;
  }

  &:hover .bottomLastImage {
    transform: scale(1.1); // Scale image on hover
    transition: transform 0.5s ease; // Add smooth transition
  }

  .bottomLastImage {
    transition: transform 0.3s ease; // Add smooth transition for scaling
  }
  &:hover .overlay {
    opacity: 1; // Show overlay on hover
    transition: opacity 0.5s ease; // Add smooth transition for overlay
  }
}

.bottomLastImage {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  position: relative;
  object-fit: cover;

  @include phone {
    height: 80px;
  }
}

.overlay {
  position: absolute;
  border-radius: 8px;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Black background with 60% opacity */
  display: flex;
  justify-content: center;
  align-items: center;

  @include phone {
    height: 80px;
  }
}

.overlayText {
  color: white;
  font-size: 32px;
  font-weight: bold;
}
