@import 'src/styles/variables.scss';

.wrapper {
}

:global {
  .ant-dropdown {
    background: $white;
    border: 1px solid rgba(163, 163, 191, 0.24);
    box-shadow: 0 24px 40px rgba(12, 0, 48, 0.12);
    border-radius: $border-radius-button;
  }
  .ant-dropdown-menu {
    border-radius: $border-radius-button;
  }

  .ant-dropdown-menu-item {
    &:hover {
      background-color: #f5f5f5 !important;
    }
  }
}
