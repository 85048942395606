@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.featureWrapper {
  border-radius: 8px;
  border: 1px solid #e7e9f1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
}

.title {
  padding-top: 0;
  margin-bottom: 2rem;
}

.bigContainter {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
}

.eventButton {
  background-color: #187dfd !important;
  color: white;
  border: none;
  border-radius: 20px;

  &:hover {
    color: $white !important;
  }
}

.featuresContainter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  @include tablet {
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
  }
}

.featureColumn {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.banner {
  width: 100%;
  height: fit-content;
  margin-top: 24px;
  background-image: url('../../../public/assets/images/report_bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  color: white;
  padding-top: 32px;
  padding-bottom: 32px;

  display: flex;
  align-items: center;
  padding-left: 16%;

  @include phone {
    background-size: cover;
    background-position: right;
    padding-left: 7%;
  }
}

.bannerTitle {
  font-size: 36px;
  font-weight: 700;
  margin: 16px 0;
}

.bannerText {
  font-size: 20px;
  font-weight: 400;
}

.bannerButtons {
  margin-top: 24px;
  display: flex;
  gap: 12px;
  @include phone {
    flex-direction: column;
  }
}
