@import 'src/styles/variables.scss';

.h1 {
  line-height: $line-height-62;
  font-size: $font-size-44;
  font-weight: $font-weight-700;
  font-family: $font-family--default;
  &.fw400 {
    font-weight: $font-weight-400;
  }

  &.fw500 {
    font-weight: $font-weight-500;
  }

  &.fw600 {
    font-weight: $font-weight-600;
  }

  &.fw700 {
    font-weight: $font-weight-700;
  }
  &.fw800 {
    font-weight: $font-weight-800;
  }
  &.fw900 {
    font-weight: $font-weight-900;
  }
}

.h2 {
  line-height: $line-height-40;
  font-size: $font-size-32;
  font-weight: $font-weight-700;
  font-family: $font-family--default;
  &.fw400 {
    font-weight: $font-weight-400;
  }

  &.fw500 {
    font-weight: $font-weight-500;
  }

  &.fw600 {
    font-weight: $font-weight-600;
  }

  &.fw700 {
    font-weight: $font-weight-700;
  }
  &.fw800 {
    font-weight: $font-weight-800;
  }
  &.fw900 {
    font-weight: $font-weight-900;
  }
}

.h3 {
  line-height: $line-height-32;
  font-size: $font-size-24;
  font-weight: $font-weight-700;
  font-family: $font-family--default;
  &.fw400 {
    font-weight: $font-weight-400;
  }

  &.fw500 {
    font-weight: $font-weight-500;
  }

  &.fw600 {
    font-weight: $font-weight-600;
  }

  &.fw700 {
    font-weight: $font-weight-700;
  }
  &.fw800 {
    font-weight: $font-weight-800;
  }
  &.fw900 {
    font-weight: $font-weight-900;
  }
}

.h4 {
  line-height: $line-height-24;
  font-size: $font-size-20;
  font-weight: $font-weight-700;
  font-family: $font-family--default;
  &.fw400 {
    font-weight: $font-weight-400;
  }

  &.fw500 {
    font-weight: $font-weight-500;
  }

  &.fw600 {
    font-weight: $font-weight-600;
  }

  &.fw700 {
    font-weight: $font-weight-700;
  }
  &.fw800 {
    font-weight: $font-weight-800;
  }
  &.fw900 {
    font-weight: $font-weight-900;
  }
}

.h5 {
  line-height: $line-height-24;
  font-size: $font-size-16;
  font-weight: $font-weight-600;
  font-family: $font-family--default;
  &.fw400 {
    font-weight: $font-weight-400;
  }

  &.fw500 {
    font-weight: $font-weight-500;
  }

  &.fw600 {
    font-weight: $font-weight-600;
  }

  &.fw700 {
    font-weight: $font-weight-700;
  }
  &.fw800 {
    font-weight: $font-weight-800;
  }
  &.fw900 {
    font-weight: $font-weight-900;
  }
}

.h6 {
  line-height: $line-height-24;
  font-size: $font-size-16;
  font-weight: $font-weight-500;
  font-family: $font-family--default;
  &.fw400 {
    font-weight: $font-weight-400;
  }

  &.fw500 {
    font-weight: $font-weight-500;
  }

  &.fw600 {
    font-weight: $font-weight-600;
  }

  &.fw700 {
    font-weight: $font-weight-700;
  }
  &.fw800 {
    font-weight: $font-weight-800;
  }
  &.fw900 {
    font-weight: $font-weight-900;
  }
}

.main {
  line-height: $line-height-20;
  font-size: $font-size-14;
  font-weight: $font-weight-400;
  font-family: $font-family--default;

  &.fw400 {
    font-weight: $font-weight-400;
  }

  &.fw500 {
    font-weight: $font-weight-500;
  }

  &.fw600 {
    font-weight: $font-weight-600;
  }

  &.fw700 {
    font-weight: $font-weight-700;
  }
  &.fw800 {
    font-weight: $font-weight-800;
  }
  &.fw900 {
    font-weight: $font-weight-900;
  }
}

.small {
  line-height: $line-height-16;
  font-size: $font-size-12;
  font-weight: $font-weight-400;
  font-family: $font-family--questrial;
  &.fw400 {
    font-weight: $font-weight-400;
  }

  &.fw500 {
    font-weight: $font-weight-500;
  }

  &.fw600 {
    font-weight: $font-weight-600;
  }

  &.fw700 {
    font-weight: $font-weight-700;
  }
  &.fw800 {
    font-weight: $font-weight-800;
  }
  &.fw900 {
    font-weight: $font-weight-900;
  }
}

.table {
  font-size: $font-size-11;
  line-height: $line-height-16;
  font-weight: $font-weight-500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}

.primary {
  color: $primary;
}
.primary-text {
  color: $primary-txt;
}

.secondary {
  color: $secondary-txt;
}

.grey {
  color: $grey-txt;
}

.grey-medium {
  color: $grey-medium-txt;
}

.secondary-light {
  color: $secondary-light-txt;
}

.red {
  color: $red-txt;
}

.blue {
  color: $primary;
}

.label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #7f86a4;
}
