@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  // max-width: 680px;
  margin-top: 24px;
}

.label {
  @include label;

  &.required {
    &:after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

.errorMessage {
  color: rgb(255, 77, 79);
  font-size: 13px;
  font-weight: 400;
  margin-top: 4px;
  visibility: hidden; // Hidden by default
}

.errorMessage.visible {
  visibility: visible; // Becomes visible when the class is applied
}

.inputError {
  border: 1px solid rgb(255, 77, 79) !important;
}

.item {
  @include reset-button;
  width: 100%;
  text-align: start;

  &.customAddress {
    color: $primary;

    &:hover {
      background: none;
    }
  }
}

.location {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include phone {
    padding-right: 0;
  }
}

.formLocation {
  margin-top: 32px;
  // width: 680px;

  @include phone {
    width: 100%;
    border-right: none;
  }
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 8px;

  @include phone {
    flex-direction: column;
  }
}

.infoLocation {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include phone {
    padding-right: 0;
  }

  &:nth-child(2) {
    margin-top: 32px;
  }
}

:global {
  .ant-dropdown-menu-item {
    &:last-child {
      &:hover {
        background: none;
      }
    }
  }
}

.mapWrapper {
  width: 100%;
  height: 500px;

  position: relative;

  @include tablet {
    height: 100vh;
  }
}

.changeButton {
  padding: 0.25rem; /* Equivalent to px-1 */
  position: absolute;
  width: 29px;
  bottom: 22rem; /* Equivalent to bottom-56 */
  right: 12px; /* Equivalent to right-2.5 */
  background-color: white;
  border: 1px solid #d1d5db; /* border-secondary-content, typically a light gray */
  z-index: 10;
  border-radius: 4px;

  &:hover {
    border-color: #6b7280; /* hover:border-neutral, typically a darker gray */
    background-color: #f3f4f6;
  }

  @include phone {
    bottom: 13rem;
  }
}
