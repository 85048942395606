@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  justify-self: center;
  padding-bottom: 8px;
  width: 220px;

  @include phone {
    width: 150px;
  }
}

.propertyImage {
  max-width: 220px;
  width: 220px;
  height: 220px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
  display: block;

  @include phone {
    max-width: 150px;
    width: 150px;
    height: 150px;
  }
}

.price {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.48px;
  margin-top: 12px;
}

.PropertyName {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  margin-bottom: 4px;
}

:global {
  .mapboxgl-popup-content {
    border-radius: 12px;
    padding-bottom: 0;
    box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.16);
  }
}

.editButton {
  position: absolute;
  left: 8px;
  top: 10px;
  color: white;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px;
  z-index: 30;
  height: 32px;
  width: 32px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.deleteButton {
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: white;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px 8px;
  z-index: 30;
  height: 32px;
  width: 32px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.heartButton {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px 8px;
  z-index: 30;
  height: 32px;
  width: 32px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.shareButton {
  position: absolute;
  right: 10px;
  top: 50px;
  color: white;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px 8px;
  z-index: 30;
  height: 32px;
  width: 32px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.label {
  max-width: 210px;

  @include phone {
    max-width: 160px;
  }
}

.propertyLink {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cardFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.googleLink {
  text-decoration: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #7c878f;
  display: flex;
  align-items: center;
  gap: 2px;
}

.Label {
  margin-bottom: 0;
}
