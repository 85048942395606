.defaultStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dcdbe4;
  height: fit-content;
}
:global {
  .ant-skeleton-button {
    min-width: 0 !important;
  }
}
