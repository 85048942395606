@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border-left: 1px solid #ced2e5;
  padding-left: 24px;
  min-height: 50vh;
  width: 100%;

  @include small-screen {
    border-left: none;
    padding-left: 0px;
  }
}

.components {
  display: flex;
  gap: 24px; /* Spacing between items */
  padding: 16px;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;

  &.more-than-4 {
    justify-content: space-around;
  }

  @include tablet {
    padding: 0;
    margin-top: 24px;
    justify-content: center;
  }
}

.loading {
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}
