@import 'src/styles/variables.scss';

.link {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 6px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 8px 0 0 8px;
  padding: 8px 0;
  text-decoration: none;
  color: #ffffff;
  font-weight: $font-weight-500;
  font-size: $font-size-16;
  transition: background-color 0.3s $animation-timing-function,
    color 0.3s $animation-timing-function;

  &:hover {
    color: #7f86a4;
  }
}

.icon {
  color: #7f86a4 !important;
}

.active {
  color: #7f86a4;
}

.counter {
  border-radius: 20px;
  border: 1px solid #7f86a4;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &.active {
    background-color: #187dfd;
    border-color: #187dfd;
  }
}
