@import 'src/styles/variables.scss';

.wrapper {
  width: fit-content;
  height: 40px;
  border-radius: 6px;
  background: $tertiary-bg;
}

.fullWidth {
  width: 100%;
}

:global {
  .ant-picker-time-panel-column {
    &:after {
      height: 0 !important;
    }
  }
  .ant-picker-header-super-next-btn {
    display: none;
  }
  .ant-picker-header-super-prev-btn {
    display: none;
  }
  .ant-picker-header-next-btn,
  .ant-picker-header-prev-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-picker-dropdown {
    z-index: 1000000 !important;
  }
}
