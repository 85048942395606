@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  // margin-bottom: 40px;

  &.withOutMargin {
    margin-bottom: 0;
  }
}

.title {
  @include page-title;

  @include phone {
    @include mobile-page-title;
  }
}

.counter {
  color: var(--grey-text, #7f86a4);
  font-family: Inter, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  /* 122.222% */
  letter-spacing: -0.72px;
}
