@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.notAuth {
  width: 100%;
  height: 100%;
}

.content {
  //padding: 32px 32px 0;
  grid-area: main;
  display: flex;
  flex-direction: column;
  grid-template-areas: none;
  height: 100vh;
  overflow-y: scroll;
  background-color: #eff0f5;

  @include phone {
    background-color: #fff;
  }

  @media (max-width: 991px) {
    padding: 16px;
    padding-top: 80px;
  }
}
