@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border: 1px solid #ced2e5;
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.16);
  min-width: 240px;
  background-color: white;

  @include phone {
    width: 100%;
  }
}

.imageWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;
}

.agenciesImage {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

.infoWrapper {
  border-top: 1px solid #ced2e5;
}
