@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  padding: 22px;
  border-radius: 12px;
  background: #fff;
  text-align: center;
  padding-top: 144px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  @include small-screen {
    padding: 94px 0 0 0;
  }

  @include phone {
    width: 100%;
    padding: 94px 16px 0;
  }
}

.numberWrapper {
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: $secondary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faqItem {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  @include phone {
    align-items: flex-start;
  }
}

.faqTitle {
  text-align: left;
}

.faqContent {
  font-size: 16px;
  font-style: normal;
  line-height: 32px; /* 160% */
  text-align: left;
  margin-left: 48px;
  margin-top: -24px;
}
