@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.image {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;

  @include small-screen {
    padding: 20px;
    height: fit-content;
  }

  @include tablet {
    justify-content: flex-start;
  }
}

.image img {
  width: 100%; /* Fixed width */
  height: calc(100vh - 48px); /* Fixed height */

  @include small-screen {
    height: 400px;
    object-fit: cover;
    border-radius: 8px;
  }

  @include tablet {
    height: 320px;
    object-fit: cover;
    border-radius: 8px;
  }

  @include phone {
    height: 240px;
    object-fit: cover;
    border-radius: 8px;
  }
}
