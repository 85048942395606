@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border-left: 1px solid #ced2e5;
  padding-left: 24px;
  min-height: 50vh;
  width: 100%;

  @include small-screen {
    border-left: none;
    padding-left: 0px;
  }
}

.empty {
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
}
