@import 'src/styles/variables.scss';

.textArea {
  height: 100px;
  & textarea {
    resize: none;
    padding: 8px 14px;
  }
}

:global {
  .ant-input-prefix {
    margin-right: 0;
  }

  .ant-input-textarea {
    position: relative;
  }

  .ant-input-textarea-show-count {
    &:after {
      white-space: nowrap;
      content: attr(data-count);
      pointer-events: none;
      position: absolute;
      bottom: -23px !important;
      right: 4px;
      color: $secondary-txt;
      font-size: 12px;
      font-weight: 400;
      display: none;
    }
  }

  textarea.ant-input {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    font-family: $font-family--default;
    color: #3c484e;
    padding: 12px 16px;
  }
}
