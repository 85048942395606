@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.mapWrapper {
  height: 100vh;
  position: relative;

  @include tablet {
    height: 100vh;
  }
}

.changeButton {
  padding: 0.25rem; /* Equivalent to px-1 */
  position: absolute;
  width: 29px;
  bottom: 22rem; /* Equivalent to bottom-56 */
  right: 11px; /* Equivalent to right-2.5 */
  background-color: white;
  border: 1px solid #d1d5db; /* border-secondary-content, typically a light gray */
  z-index: 20;
  border-radius: 4px;

  &:hover {
    border-color: #6b7280; /* hover:border-neutral, typically a darker gray */
    background-color: #f3f4f6;
  }

  @include phone {
    bottom: 20rem;
  }
}

.pricePanel {
  position: relative;
  background-color: white;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
  padding: 2px 4px;
  border-radius: 4px;
}

.marker {
  cursor: pointer;
}

.icon {
  position: absolute; /* Positions the icon absolutely relative to the pricePanel */
  top: 90%; /* Centers the icon vertically */
  z-index: 2; /* Ensures the icon is above other elements */

  &.animated {
    animation: flash 1s 5; // Add animation here
  }
}

@keyframes flash {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.showList {
  position: absolute;
  color: $primary;
  background-color: $secondary;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1.6px solid #ced2e5;
  bottom: 0;
  left: 50%; /* Equivalent to bottom-56 */
  transform: translate(-50%, -50%); /* Equivalent to right-2.5 */
  z-index: 20;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
  height: 50px;
  justify-content: center;
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.16);

  &:hover {
    color: white !important;
    background-color: $primary !important;
  }
}

:global {
  .mapboxgl-popup-close-button {
    display: none !important;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
}

.pricePopup {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.48px;
  margin-top: 8px;
}

.favourites {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 16px;
  margin-left: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ced2e5;
}

.notification {
  margin-left: 16px;
}
.propertyContents {
  overflow-y: auto;
  padding: 0 16px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    width: 0;
    display: none;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #7f86a4;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $grey-light-1;
  }

  @include phone {
    display: flex;
    align-items: center;
    padding: 0 0 4px;

    &::-webkit-scrollbar {
      height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      height: 0;
      display: none;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $grey-light-1;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $grey-light-1;
    }
  }
}

.propertyList {
  position: absolute;
  padding-top: 8px;
  border-radius: 12px;
  top: 180px;
  left: 50px;
  bottom: 20px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 300px;

  @include tablet {
    left: 10px;
  }

  @include phone {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    padding: 0;
    height: fit-content;
    border-radius: 0;
    width: 100%;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    gap: 0;
  }
}

.propertyListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include phone {
    display: none;
  }
}
