@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  margin-left: 100px;
  margin-right: 90px;
  padding-top: 144px;
  padding-bottom: 48px;
  display: flex;
  min-height: calc(100vh - 120px);

  @include small-screen {
    margin-left: 40px;
    margin-right: 40px;
  }

  @include tablet {
    padding-top: 84px;
  }

  @include phone {
    margin-left: 24px;
    margin-right: 24px;
  }
}

.leftMenu {
  width: 280px;
  height: 50vh;

  @include small-screen {
    display: none;
  }
}

.content {
  width: 100%;
}

.menuItem button {
  color: $primary;
  padding-left: 0;
  font-size: 18px;
  font-style: normal;
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: 700;
  line-height: 26px; /* 130% */
  letter-spacing: -0.4px;

  &:focus {
    color: black;
  }
}
.active button {
  color: black;
}

.logoutItem button {
  color: $grey;
  padding-left: 0;
  font-size: 18px;
  font-style: normal;
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: 700;
  line-height: 26px; /* 130% */
  letter-spacing: -0.4px;

  &:focus {
    color: black;
  }
}
