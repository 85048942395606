@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border: 1px solid #ced2e5;
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 16px;

  @include phone {
    width: 100%;
  }
}

.imageWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #ced2e5;
  padding-bottom: 16px;
}

.agenciesImage {
  display: flex;
  align-items: center;
  gap: 12px;
}

.AgencyLink {
  color: black;
  width: 140px;

  @include phone {
    width: 130px;
  }
}

.agencyDiv {
  width: fit-content;
}
