@import 'src/styles/variables.scss';
@import 'src/styles/main.scss';
@import 'src/styles/mixins.scss';

.content {
  padding-bottom: 16px;
  border-bottom: 1px solid $dividers;
  margin-bottom: 16px;
}

.active {
  // background-color: $grey-light-2;
  //border-radius: 24px;

  &.circle {
    border-radius: 24px;
  }
}

:global {
  .ant-popover-inner {
    padding: 16px;
    border-radius: 8px;
  }

  .ant-popover-title {
    min-width: fit-content;
    padding: 0 !important;
    border-bottom: none !important;
    min-height: 0 !important;
    color: $primary-txt !important;
    margin-bottom: 16px;
  }

  .ant-popover-inner-content {
    padding: 0 !important;
  }
}
