@import 'src/styles/variables.scss';

:global {
  .ant-tooltip-inner {
    border-radius: $border-radius-tooltip;
    background: $color-simple-black;
    padding: 8px;
  }

  .ant-tooltip-arrow {
    span {
      background: $color-simple-black;
    }
  }
}
