@import 'src/styles/variables.scss';

.expandIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey-dark-1;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid $grey-dark-1;
}

:global {
  .ant-collapse {
    border: none;
  }

  .ant-collapse > .ant-collapse-item {
    border-top: 1px solid #ced2e5;
    border-bottom: none;
  }

  .ant-collapse > .ant-collapse-item-active {
    border-top: 1px solid #ced2e5;
    border-bottom: none;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-content {
    border: none;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    background-color: white;
    align-items: center;

    &:hover {
      color: $black;
    }

    &:focus {
      color: $black;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    margin-right: 8px;
  }
}
