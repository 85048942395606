@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.sider {
  grid-area: nav;
  box-sizing: border-box;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  background-color: #eff0f5;

  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  &.dark {
    background-color: #1b1e21;
  }

  &.light {
    background-color: #7f86a4;
  }

  @include tablet {
    overflow: hidden;
    padding-top: 32px;
  }
}

.sider.long {
  width: 96px;
  position: sticky;
  top: 24px;
  transition: width 0.3s, left 0.3s $animation-timing-function;

  @include tablet {
    width: 0;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  @include phone {
  }

  &.opened {
    width: 401px;

    @include tablet {
      width: 340px;
    }

    @include phone {
      width: 340px;
    }
  }
}

.header {
  min-height: 144px;

  &.dark {
    background-color: $black50;
    color: $grey-dark-2;
  }

  &.light {
    background-color: #7f86a4;
  }

  @include tablet {
    min-height: 100px;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
