@import 'src/styles/variables.scss';

.link {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 18px;
  text-decoration: none;
  color: $primary-txt;
  font-weight: $font-weight-500;
  font-size: $font-size-16;
  transition: all 0.2s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: $primary;

    svg {
      filter: grayscale(0);
    }
  }

  svg {
    filter: grayscale(100%);
    transition: all 0.2s ease-in-out;
  }
}

.active {
  color: $primary;

  svg {
    filter: grayscale(0);
  }
}
