@import 'src/styles/variables.scss';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  a {
    color: $color-breadcrumbs-link;
  }
  a:hover {
    color: #2e61e6;
  }
  a:active {
    color: #2957cf;
  }
}

.currentLocation {
  color: $tertiary;
}

.divider {
  height: 8px;
  color: $color-breadcrumbs-disabled;
}

.breadcrumbWrapper {
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
