.wrap {
  width: 100vw;
  height: 100vh;
  perspective: 1000;
  backface-visibility: hidden;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgb(90 153 212 / 50%);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
