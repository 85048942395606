@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border-left: 1px solid #ced2e5;
  padding-left: 24px;

  @include small-screen {
    border-left: none;
    padding-left: 0px;
  }
}

.upload {
  background: #fff;
  margin-top: 8px;
}

.uploadBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
}

.uploadedFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.chooseButton {
  height: 32px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: #ebddff;
  color: $primary;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
  border-radius: 4px !important;
}

.uploadWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.uploadedFiles {
  display: flex;
  align-items: center;
  gap: 24px;
}

.uploadedImage {
  border-radius: 50%;
}

.fileUpload {
  width: 100%;
  height: 2px;
  margin-top: 8px;

  .fileContent {
    background: rgb(2, 0, 36);
    background: linear-gradient(
      171deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 9, 121, 1) 0%,
      rgba(0, 212, 255, 1) 56%,
      rgba(0, 255, 1, 1) 98%
    );
    width: 0;
    height: 100%;
  }

  .fill {
    animation: progress linear;
    animation-fill-mode: forwards;
    animation-duration: 50s;
  }

  .done {
    animation: progress linear;
    animation-fill-mode: forwards;
    animation-duration: 3s;
  }
}

.profileImage {
  border-bottom: 1px solid #ced2e5;
  padding-bottom: 24px;
}

.editUser {
  border: 1px solid #ced2e5;
  border-radius: 12px;
  padding: 24px;
}

.password {
  border: 1px solid #ced2e5;
  border-radius: 12px;
  padding: 24px;
  height: fit-content;
}

.content {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @include phone {
    grid-template-columns: 1fr;
  }
}
