@import 'src/styles/mixins.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  width: 100%;

  @include small-screen {
    display: flex;
    flex-direction: column;
  }
}
