@import 'src/styles/variables.scss';

.container {
  position: relative;
  display: inline-block;
}

.secondIcon {
  position: absolute;
  left: 50%;
  bottom: 0;
  border: 1px solid white;
  border-radius: 50%;
}

.loader {
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
