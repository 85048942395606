@import 'src/styles/variables.scss';

.spinWrap {
  position: absolute;
  background-color: $spin-bg;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  z-index: -1;
  .spin {
    display: none;
  }
  &.spinVisible {
    z-index: 1;
    opacity: 1;
    .spin {
      display: block;
    }
  }
}
