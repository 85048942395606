.wrapper {
  padding: 22px;
  border-radius: 12px;
  background-color: #fff;
}

.searchBlock {
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;
  margin-bottom: 24px;
}

.title {
  margin-bottom: 24px;
}

.searchBtn {
}
